import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-salem-oregon.png'
import image0 from "../../images/cities/scale-model-of-bush-house-museum-in-salem-oregon.png"
import image1 from "../../images/cities/scale-model-of-bush's-pasture-park-in-salem-oregon.png"
import image2 from "../../images/cities/scale-model-of-deepwood-museum-&-gardens-in-salem-oregon.png"
import image3 from "../../images/cities/scale-model-of-riverfront-city-park-in-salem-oregon.png"
import image4 from "../../images/cities/scale-model-of-willamette-valley-visitors-association-in-salem-oregon.png"
import image5 from "../../images/cities/scale-model-of-tourism-division-in-salem-oregon.png"
import image6 from "../../images/cities/scale-model-of-travel-oregon---salem-office-in-salem-oregon.png"
import image7 from "../../images/cities/scale-model-of-salem's-riverfront-carousel-in-salem-oregon.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Salem'
            state='Oregon'
            image={image}
            lat='44.9428975'
            lon='-123.03509630000002'
            attractions={ [{"name": "Bush House Museum", "vicinity": "600 Mission St SE, Salem", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.931431, "lng": -123.03926239999998}, {"name": "Bush's Pasture Park", "vicinity": "890 Mission St SE, Salem", "types": ["park", "point_of_interest", "establishment"], "lat": 44.9311466, "lng": -123.03499160000001}, {"name": "Deepwood Museum & Gardens", "vicinity": "1116 Mission St SE, Salem", "types": ["museum", "point_of_interest", "establishment"], "lat": 44.9296711, "lng": -123.0317536}, {"name": "Riverfront City Park", "vicinity": "200 Water St NE, Salem", "types": ["park", "point_of_interest", "establishment"], "lat": 44.9419751, "lng": -123.04279989999998}, {"name": "Willamette Valley Visitors Association", "vicinity": "388 State St Suite 100, Salem", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 44.9398304, "lng": -123.0391998}, {"name": "Tourism Division", "vicinity": "775 Summer St NE, Salem", "types": ["point_of_interest", "establishment"], "lat": 44.9454638, "lng": -123.02754600000003}, {"name": "Travel Oregon - Salem Office", "vicinity": "530 Center St NE Suite 200, Salem", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 44.9426147, "lng": -123.0351991}, {"name": "Salem's Riverfront Carousel", "vicinity": "101 Front St NE, Salem", "types": ["store", "point_of_interest", "establishment"], "lat": 44.9409335, "lng": -123.0430647}] }
            attractionImages={ {"Bush House Museum":image0,"Bush's Pasture Park":image1,"Deepwood Museum & Gardens":image2,"Riverfront City Park":image3,"Willamette Valley Visitors Association":image4,"Tourism Division":image5,"Travel Oregon - Salem Office":image6,"Salem's Riverfront Carousel":image7,} }
       />);
  }
}